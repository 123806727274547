import React from "react"

import { graphql } from "gatsby"
import { Heading, Text, Box } from "grommet"
import Markdown from "../../components/common/Markdown"
import PageLayout from "../../components/Layouts/PageLayout"

export const query = graphql`
  query($slug: String!) {
    strapiStatic(slug: { eq: $slug }) {
      id
      seo {
        id
        noIndex
        metaDescription
        metaTitle
        shareImage {
          width
          height
          url
          localFile {
            childImageSharp {
              gatsbyImageData(
                placeholder: TRACED_SVG
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
        }
      }
      published_at
      slug
      title
      content
      created_at
    }
  }
`

const StaticPage = ({
  data: {
    strapiStatic: { content, published_at, title },
  },
}) => {
  return (
    <PageLayout>
      <PageLayout.Heading heading={title} />
      <PageLayout.Content>
        <Markdown>{content}</Markdown>
        <Text size="xsmall">
          Published At: {new Date(published_at).toString()}
        </Text>
      </PageLayout.Content>
    </PageLayout>
  )
}

StaticPage.propTypes = {}

export default StaticPage
