import React from "react"
import { Box, Text, Heading as GrommetHeading } from "grommet"

const PageLayout = ({ children, width = "large", ...props }) => {
  return (
    <Box align="center" fill flex="grow" gap="large" width={width} {...props}>
      {children}
    </Box>
  )
}

const Heading = ({ heading, sub_heading, ...props }) => {
  const SubHeading = () =>
    sub_heading ? (
      typeof sub_heading === "string" ? (
        <Text>{sub_heading}</Text>
      ) : (
        sub_heading
      )
    ) : null

  return (
    <Box align="center" gap="small" {...props}>
      <GrommetHeading level={1} margin="none">
        {heading}
      </GrommetHeading>
      <SubHeading />
    </Box>
  )
}

PageLayout.Heading = Heading

const Content = ({ children, ...props }) => {
  return (
    <Box fill flex="grow" width="large" pad="large" {...props}>
      {children}
    </Box>
  )
}

PageLayout.Content = Content

PageLayout.propTypes = {}

export default PageLayout
